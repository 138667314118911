@import url("https://fonts.cdnfonts.com/css/itc-avant-garde-gothic-std-book");

@font-face {
  font-family: "GE SS Two Medium";
  src: url("https://db.onlinewebfonts.com/t/125063e353c1f65c55ba1e3811969217.eot");
  /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/125063e353c1f65c55ba1e3811969217.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("https://db.onlinewebfonts.com/t/125063e353c1f65c55ba1e3811969217.woff2") format("woff2"),
    /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/125063e353c1f65c55ba1e3811969217.woff") format("woff"),
    /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/125063e353c1f65c55ba1e3811969217.ttf") format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("https://db.onlinewebfonts.com/t/125063e353c1f65c55ba1e3811969217.svg#GE SS Two Medium") format("svg");
  /* iOS 4.1- */
}

@font-face {
  font-family: "GE SS Two Light";
  src: url("https://db.onlinewebfonts.com/t/02f502e5eefeb353e5f83fc5045348dc.eot");
  /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/02f502e5eefeb353e5f83fc5045348dc.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("https://db.onlinewebfonts.com/t/02f502e5eefeb353e5f83fc5045348dc.woff2") format("woff2"),
    /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/02f502e5eefeb353e5f83fc5045348dc.woff") format("woff"),
    /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/02f502e5eefeb353e5f83fc5045348dc.ttf") format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("https://db.onlinewebfonts.com/t/02f502e5eefeb353e5f83fc5045348dc.svg#GE SS Two Light") format("svg");
  /* iOS 4.1- */
}

@font-face {
  font-family: "GE SS Two Bold";
  src: url("https://db.onlinewebfonts.com/t/aaff40b5e8234dc862e4df217e40cf1e.eot");
  /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/aaff40b5e8234dc862e4df217e40cf1e.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("https://db.onlinewebfonts.com/t/aaff40b5e8234dc862e4df217e40cf1e.woff2") format("woff2"),
    /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/aaff40b5e8234dc862e4df217e40cf1e.woff") format("woff"),
    /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/aaff40b5e8234dc862e4df217e40cf1e.ttf") format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("https://db.onlinewebfonts.com/t/aaff40b5e8234dc862e4df217e40cf1e.svg#GE SS Two Bold") format("svg");
  /* iOS 4.1- */
}

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  padding: 0;
  margin: 0;
}

.timeline {
  max-width: 100vw !important;
}

body {
  background-color: #16489f !important;
  background: #16489f !important;
}